<template>
  <div class="cooperation-view1-main">
    <div class="cooperation-view1-content">
      <div class="flex align-items-center justify-content-center mt20">
        <div class="cooperation-view1-content-title" :class="{ 'cooperation-view1-content-title-active': active==0 }" @click="active = 0">{{$t('lang.AllPartner')}}</div>
        <div class="cooperation-view1-content-title" :class="{ 'cooperation-view1-content-title-active': active==1 }" @click="active = 1">{{$t('lang.PoultryEnterprise')}}</div>
        <div class="cooperation-view1-content-title" :class="{ 'cooperation-view1-content-title-active': active==2 }" @click="active = 2">{{$t('lang.PigEnterprise')}}</div>
        <div class="cooperation-view1-content-title" :class="{ 'cooperation-view1-content-title-active': active==3 }" @click="active = 3">{{$t('lang.ConvergedEnterprise')}}</div>
        <div class="cooperation-view1-content-title" :class="{ 'cooperation-view1-content-title-active': active==4 }" @click="active = 4">{{$t('lang.UniversityEnterpriseCooperation')}}</div>
      </div>
      <div class="mt20 cooperation-view1-img">
        <img src="../../../assets/images/Cooperation/logo_u1992-2.svg" v-if="getShow(2)">
        <img src="../../../assets/images/Cooperation/logo_u1992-3.svg" v-if="getShow(1)">
        <img src="../../../assets/images/Cooperation/logo_u1992-4.svg" v-if="getShow(1)">
        <img src="../../../assets/images/Cooperation/logo_u1992-5.svg" v-if="getShow(2)">
        <img src="../../../assets/images/Cooperation/logo_u1992-6.svg" v-if="getShow(2)">
        <img src="../../../assets/images/Cooperation/logo_u1992-7.svg" v-if="getShow(2)">
        <img src="../../../assets/images/Cooperation/logo_u1992-8.svg" v-if="getShow(3)">
        <img src="../../../assets/images/Cooperation/logo_u1992-9.svg" v-if="getShow(2)">
        <img src="../../../assets/images/Cooperation/logo_u1992-10.svg" v-if="getShow(3)">
        <img src="../../../assets/images/Cooperation/logo_u1992-11.svg" v-if="getShow(3)">
        <img src="../../../assets/images/Cooperation/logo_u1992-12.svg" v-if="getShow(4)">
        <img src="../../../assets/images/Cooperation/logo_u1992-13.svg" v-if="getShow(4)">
        <img src="../../../assets/images/Cooperation/logo_u1992-14.svg" v-if="getShow(4)">
        <img src="../../../assets/images/Cooperation/logo_u1992-15.svg" v-if="getShow(1)">
        <img src="../../../assets/images/Cooperation/logo_u1992-16.svg" v-if="getShow(4)">
        <img src="../../../assets/images/Cooperation/logo_u1992-17.svg" v-if="getShow(1)">
        <img src="../../../assets/images/Cooperation/logo_u1992-18.svg" v-if="getShow(2)">
        <img src="../../../assets/images/Cooperation/logo_u1992-19.svg" v-if="getShow(2)">
        <img src="../../../assets/images/Cooperation/logo_u1992-20.svg" v-if="getShow(2)">
        <img src="../../../assets/images/Cooperation/logo_u1992-21.svg" v-if="getShow(2)">
        <img src="../../../assets/images/Cooperation/logo_u1992-22.svg" v-if="getShow(2)">
        <img src="../../../assets/images/Cooperation/logo_u1992-23.svg" v-if="getShow(2)">
        <img src="../../../assets/images/Cooperation/logo_u1992-24.svg" v-if="getShow(2)">
        <img src="../../../assets/images/Cooperation/logo_u1992-25.svg" v-if="getShow(2)">
        <img src="../../../assets/images/Cooperation/logo_u1992-26.svg" v-if="getShow(1)">
        <img src="../../../assets/images/Cooperation/logo_u1992-27.svg" v-if="getShow(4)">
        <img src="../../../assets/images/Cooperation/logo_u1992-28.png" v-if="getShow(4)">
        <img src="../../../assets/images/Cooperation/logo_u1992-29.png" v-if="getShow(1)">
        <img src="../../../assets/images/Cooperation/logo_u1992-30.png" v-if="getShow(1)">
        <img src="../../../assets/images/Cooperation/logo_u1992-31.png" v-if="getShow(1)">
        <img src="../../../assets/images/Cooperation/logo_u1992-32.png" v-if="getShow(1)">
        <img src="../../../assets/images/Cooperation/logo_u1992-33.png" v-if="getShow(1)">
        <img src="../../../assets/images/Cooperation/logo_u1992-34.png" v-if="getShow(1)">
        <img src="../../../assets/images/Cooperation/logo_u1992-0.svg" v-if="getShow(3)" >
        <img src="../../../assets/images/Cooperation/logo_u1992-1.svg" v-if="getShow(3)">
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "view1",
  data() {
    return {
      active: 0
    }
  },
  methods: {
    getShow(val) {
      if (this.active == 0) {
        return true
      }else {
        return this.active == val
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cooperation-view1-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .cooperation-view1-content {
    width: 70%;
    //background: #42b983;
    .cooperation-view1-content-title {
      width: 20%;
      text-align: center;
      font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
      font-weight: 650;
      font-style: normal;
      font-size: 18px;
      padding: 5px;
      border-bottom: 1px solid #eeeeee;
      color: #00834b;
      &:hover {
        color: #2c4198;
        border-bottom: 2px solid #2c4198;
      }
    }
    .cooperation-view1-content-title-active {
      color: #2c4198;
      border-bottom: 2px solid #2c4198;
    }
    .cooperation-view1-img {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      img {
        width: 18%;
        margin: 1%;
      }
    }
  }
}
</style>
